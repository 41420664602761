<template>
  <div>
    <el-drawer v-model="addShow" title="缴费项目" :before-close="handleClose">
      <el-form
        ref="addPaymentRef"
        :model="addPaymentForm"
        :rules="rules"
        label-width="110px"
        status-icon
      >
        <!-- <el-form-item label="发起部门" prop="college_id">
          <el-select
            v-model="addPaymentForm.college_id"
            placeholder="请输入选择系部"
          >
            <el-option
              v-for="item in departmentAry"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="缴费项目名称" prop="name">
          <el-input v-model="addPaymentForm.name" />
        </el-form-item>
        <!-- <el-form-item label="缴费金额" prop="amt">
          <el-input v-model="addPaymentForm.amt" />
        </el-form-item> -->
        <el-form-item label="缴费时间" prop="time">
          <el-date-picker
            v-model="addPaymentForm.time"
            type="datetimerange"
            range-separator="到"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="YYYY/MM/DD HH:mm:ss "
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm(addPaymentRef)"
            >确认</el-button
          >
          <el-button @click="resetForm(addPaymentRef)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { listOfSelectionOfHospitalsAPI } from '@/api/specialized'
import { paymentItemAPI, paymentDetailsAPI } from '@/api/social-payment'
import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },
  onSocialPaymentList: {
    type: Function,
    required: true
  },
  payTheFeesID: {
    type: String
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const addShow = useVModel(props) // 得到一个响应式的数据，可以直接修改

const handleClose = (done) => {
  addShow.value = false
}

/** 表单 S */
const addPaymentForm = ref({
  name: '', // 项目名称
  amt: '', // 金额
  start_time: '', // 开始时间
  end_time: '', // 结束时间
  college_id: '', // 部门 id
  time: []
})

const rules = ref({
  name: [{ required: true, message: '必须填写缴费项目名称', trigger: 'blur' }],
  time: [{ required: true, message: '必须选择缴费时间', trigger: 'blur' }]
})

watch(
  () => addPaymentForm.value.time,
  (val) => {
    console.log(val)
    if (val) {
      addPaymentForm.value.start_time = val[0]
      addPaymentForm.value.end_time = val[1]
    } else {
      addPaymentForm.value.start_time = ''
      addPaymentForm.value.end_time = ''
    }
  },
  {
    immediate: true,
    deep: true
  }
)

// 部门下拉
const departmentAry = ref([])
const onListOfSelectionOfHospitals = async () => {
  const data = await listOfSelectionOfHospitalsAPI()
  departmentAry.value = data
}
onListOfSelectionOfHospitals()

// 详情
const onPaymentDetails = async (id) => {
  const data = await paymentDetailsAPI({
    id
  })
  addPaymentForm.value = data
  addPaymentForm.value.time = []
  addPaymentForm.value.time.push(data.start_time)
  addPaymentForm.value.time.push(data.end_time)
}

watch(
  () => props.payTheFeesID,
  (val) => {
    if (val) {
      onPaymentDetails(val)
    } else {
      addPaymentForm.value = {
        name: '', // 项目名称
        amt: '', // 金额
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        college_id: '', // 部门 id
        time: []
      }
    }
  },
  {
    deep: true,
    immediate: true
  }
)

// 添加
const addPaymentRef = ref(null)
const submitForm = async (formEl) => {
  console.log(formEl)
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      console.log(addPaymentForm.value)
      await paymentItemAPI(addPaymentForm.value)
      if (props.payTheFeesID) {
        ElMessage.success('缴费编辑成功。')
      } else {
        ElMessage.success('缴费添加成功。')
      }

      addShow.value = false
      props.onSocialPaymentList()
      addPaymentForm.value = {
        name: '', // 项目名称
        amt: '', // 金额
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        college_id: '', // 部门 id
        time: []
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  addShow.value = false
}

/** 表单 E */
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input {
  width: 100% !important;
}

::v-deep .el-select {
  width: 100%;
}
</style>
