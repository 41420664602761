<template>
  <div>
    <el-form>
      <el-row :gutter="20">
        <!-- <el-col :span="6">
          <el-form-item label="请输入选择系部">
            <el-select v-model="search.college_id" placeholder="请输入选择系部">
              <el-option
                v-for="item in departmentAry"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="6">
          <el-form-item label="请输入缴费名称">
            <el-input v-model="search.name" placeholder="请输入缴费名称">
            </el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="6">
          <el-form-item label="请选择日期">
            <el-date-picker
              v-model="value"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col> -->

        <el-col :span="2">
          <el-button type="primary" @click="searchBtn"> 搜索 </el-button>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-button type="primary" @click="addBtn"> 添加 </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->
    <div class="table">
      <el-table :data="paymentList" border style="width: 100%">
        <el-table-column prop="number" label="缴费编号" />
        <el-table-column
          prop="name"
          label="缴费项目名称
"
        />
        <el-table-column prop="create_time" label="发起时间" />
        <el-table-column prop="start_time" label="开始时间" />
        <el-table-column prop="end_time" label="结束时间" />
        <!-- <el-table-column prop="amt" label="金额" />
        <el-table-column label="部门">
          <template v-slot="{ row }">
            {{ row.college_name }}
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template #default="{ row }">
            <el-button
              type="primary"
              plain
              size="small"
              @click="onEditBtn(row.id)"
            >
              编辑</el-button
            >
            <el-button size="small" type="danger" @click="onDeletebtn(row)"
              >删除</el-button
            >
            <el-button
              size="small"
              type="success"
              @click="router.push(`/finance/detail/${row.id}/${row.number}`)"
            >
              详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-row style="margin-top: 20px">
        <el-col>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="search.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="search.total"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </div>

    <!-- 添加缴费 -->
    <add-payment
      v-model="addShow"
      :payTheFeesID="payTheFeesID"
      :onSocialPaymentList="onSocialPaymentList"
    ></add-payment>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import addPayment from './components/addPayment.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { socialPaymentListAPI, moneyDeleteAPI } from '@/api/social-payment'
import { listOfSelectionOfHospitalsAPI } from '@/api/specialized'
import { useRouter } from 'vue-router'
const router = useRouter()
/** 搜索 S */

const search = ref({
  page: 1, // 当前页
  limit: 10, // 每页显示条数
  total: 0,
  college_id: '',
  name: ''
})

// 部门下拉
const departmentAry = ref([])
const onListOfSelectionOfHospitals = async () => {
  const data = await listOfSelectionOfHospitalsAPI()
  departmentAry.value = data
}
onListOfSelectionOfHospitals()

const searchBtn = () => {
  onSocialPaymentList()
}

const addShow = ref(false)
const addBtn = () => {
  payTheFeesID.value = ''
  addShow.value = true
}

/** 搜索 E */

/** 表格 S */
// // 表格数据
// const tableData = ref([])
// const onSocialPaymentList = async () => {
//   const { data, total } = await payOrderList(search.value)
//   search.value.total = total
//   tableData.value = data
// }
// onSocialPaymentList()
/** 表格 E */

// 缴费列表
const paymentList = ref([])
// 缴费
const onSocialPaymentList = async () => {
  const { data, total } = await socialPaymentListAPI(search.value)
  paymentList.value = data
  search.value.total = total
}
onSocialPaymentList()

// 编辑
const payTheFeesID = ref('')
const onEditBtn = (id) => {
  payTheFeesID.value = id
  addShow.value = true
}

// 删除
const onDeletebtn = ({ id }) => {
  ElMessageBox.alert('是否删除该缴费', '提示', {
    confirmButtonText: '确认',
    callback: async () => {
      console.log(id)
      await moneyDeleteAPI({ id })
      onSocialPaymentList()
      ElMessage({
        type: 'success',
        message: '缴费信息删除成功'
      })
    }
  })
}
/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onSocialPaymentList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onSocialPaymentList()
}
/**
 *  分页 E
 */
</script>

<style lang="scss" scoped>
.table {
  margin-top: 20px;
}
</style>
